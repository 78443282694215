import React from "react";

const SocialLogo = props => (
  <a
    href={props.link}
    rel="noopener"
    target="_blank"
    className={`${props.name}-logo`}
    title={props.name === "rss" ? "Right click to copy RSS feed address" : null}
  >
    <img src={props.logo} alt={`${props.name} logo`} height={props.height} />
  </a>
);

export default React.memo(SocialLogo);
