import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import SocialLogo from "../SocialLogo";
import ProfileImage from "../../assets/images/matthias-kupperschmidt-freelancer-analytics-seo.jpg";
import TagIcon from "../../assets/icons/label-dark.svg";
import Twitterlogo from "../../assets/icons/twitter-logo-dark.svg";
import Linkedinlogo from "../../assets/icons/linkedin-sign-dark.svg";
import Facebooklogo from "../../assets/icons/facebook-logo-dark.svg";

const StyledAuthorContainer = styled.div`
  margin: 1rem 0rem;
  display: flex;
  justify-content: space-between;
  background: var(--background);
  max-width: 700px;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid var(--foreground);

  .details {
    display: flex;
    align-items: space-between;
  }

  .imagewrapper {
    width: 40px;
    height: 40px;
    align-self: flex-end;
  }
  .avatar {
    width: 40px;
    height: 40px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    line-height: 1rem;
  }
  .row1 > div,
  .row1 > a,
  .row2 > div {
    display: inline;
    margin-left: 0.5rem;
  }
  .row1 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: var(--copy);
    margin-top: 0.2rem;
    white-space: nowrap;
  }

  .row2 {
    align-items: flex-end;
    font-size: 14px;
    color: var(--foreground);
  }

  .date::after {
    content: "•";
    margin-left: 0.5rem;
  }

  .followBtn {
    color: var(--foreground);
    font-size: 12px;
    padding: 0rem 0.4rem;
    border: 1px solid var(--foreground);
    border-radius: 4px;

    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
    &:active {
      transform: translateY(1px);
    }
  }

  .tagcontainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .tag {
    align-self: flex-end;
    display: flex;
    align-items: center;
    font-size: 12px;
    overflow: hidden;
    background: var(--foreground);
    border-radius: 4px;
    margin-left: 0.3rem;
    white-space: nowrap;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.1rem;
  }

  .tagicon {
    height: 9px;
    transform: scaleX(-1);
    margin-right: 0.25rem;
  }

  .socialshare {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    & > a {
      margin: 0rem 0.75rem;
    }
    & > a:last-of-type {
      margin-right: 0rem;
    }
  }

  .socialshare > span {
    color: var(--foreground);
    font-size: 14px;
    height: 2.1rem;
  }

  @media (max-width: 480px) {
    flex-direction: column;

    .details {
      order: 2;
    }

    .socialshare {
      margin-bottom: 1rem;
    }
  }
`;

const AuthorContainer = props => {
  const tags = props => {
    if (props.showTags && props.tags) {
      return (
        <div className="tagcontainer">
          {props.tags.map((tag) => {
            const [tagName, tagPath] = tag.split(":");
            return (
              <Link to={tagPath} className="tag" key={tagName}>
                <img src={TagIcon} alt="tag icon svg" className="tagicon" key={tagName} /> {tagName}
              </Link>
            );
          })}
        </div>
      );
    }
  };

  const socialShare = (props) => {
    if (props.showShare) {
      return (
        <div className="socialshare">
          <span>share</span>
          <SocialLogo
            logo={Twitterlogo}
            link={`https://twitter.com/intent/tweet?text=${typeof window !== "undefined" && window.location.href}`}
            name="twitter"
            height="24px"
          />
          <SocialLogo
            logo={Facebooklogo}
            link={`https://www.facebook.com/sharer/sharer.php?u=${
              typeof window !== "undefined" && window.location.href
              }`}
            name="linkedin"
            height="24px"
          />
          <SocialLogo
            logo={Linkedinlogo}
            link={`https://www.linkedin.com/shareArticle?mini=true&url=${
              typeof window !== "undefined" && window.location.href
              }&title=${typeof window !== "undefined" && window.document.title}&source=share`}
            name="facebook"
            height="24px"
          />
        </div>
      );
    }
  };

  return (
    <StyledAuthorContainer className="authorcontainer">
      <div className="details">
        <a to="/about" className="imagewrapper">
          <img src={ProfileImage} alt="author avatar" className="avatar" />
        </a>
        <div className="wrapper">
          <div className="row1">
            <div className="author">{props.authorName}</div>
            <a href="https://twitter.com/intent/follow?screen_name=mattzipan">
              <div className="followBtn">Follow</div>{" "}
            </a>
          </div>
          <div className="row2">
            <div className="date">{props.publishingDate}</div>
            <div className="readtime">{props.timeToRead} read</div>
          </div>
        </div>
      </div>

      {tags(props)}
      {socialShare(props)}
    </StyledAuthorContainer>
  );
};

export default React.memo(AuthorContainer);
