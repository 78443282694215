import React from "react";
import styled from "styled-components";

const StyledImgContainer = styled.div`
  margin: 0 auto !important;
  max-width: ${props => (props.width ? props.width : "300px")};
`;

const ImgContainerFixed = props => (
  <StyledImgContainer width={props.width} className="imagecontainer fixed">
    {props.children}
  </StyledImgContainer>
);

export default ImgContainerFixed;
