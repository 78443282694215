import React from "react";
import CodeBlock from "./mdxComponents/codeBlock";

function snippetJS({ caption, children }) {
  return (
    <figure className="codesnippet">
      <pre>
        <CodeBlock className="language-js">{children}</CodeBlock>
      </pre>
      <figcaption>{caption}</figcaption>
    </figure>
  );
}

export default snippetJS;
