import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div``;

const StyledDetails = styled.details`
  margin-bottom: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 1.3rem;

  &[open] > summary {
    border-left: 2px solid var(--primary);
    background: rgba(0,0,0,0.07);
  }
`;

const StyledSummary = styled.summary`
  font-weight: bold;
  cursor: pointer;
  color: inherit;
  display: block;
  padding: 0.7rem 0.2rem 0.7rem 0.7rem;
  border-left: 2px solid transparent;
  white-space: wrap;
  text-decoration: none;
  
  &:hover {
    border-left: 2px solid var(--primary);
    background: rgba(0,0,0,0.07);
  }

  +p {
    /* background: yellow; */
    margin: 1rem 1rem 0rem 1rem;
  }
`;



const FAQComponent = ({ data, exceptions }) => {
  return (
    <>
      <StyledDiv>
        {data.mainEntity.map((question, index) => {
          // Check if the question's name is in the exceptions array
          if (exceptions?.includes(question.name)) {
            return null; // Do not render this FAQ
          }

          return (
            <StyledDetails key={index}>
              <StyledSummary>{question.name}</StyledSummary>
              <p>{question.acceptedAnswer.text}</p>
            </StyledDetails>
          );
        })}
      </StyledDiv>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </>
  );
};

export default FAQComponent;
